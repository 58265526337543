@import url(https://fonts.googleapis.com/css?family=Montserrat|Open+Sans|Quicksand&display=swap);
:root {
    --bgcolor: #fff;
    --logodarkcolor : #3C4858;
    --logosecondcolor: #545F6D;
    --primaryBlue: #1093c9;
    --activeBlue: #107aa5;
    --inactiveBlue: #1093c9;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}
.header {
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    height: 64px;
    text-align: center;
    background-color: var(--bgcolor);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
}

.header-mobile {
    justify-content: center;
    height: 36px;
}

.header-logo {
    width: 240px;
    height: 100%;
    padding: 0px 5%;
}

.header-logo-image {
    width: 180px;
    height: 100%;
    background-image: url('/images/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}
.footer {
    width: 100%;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color:black;
    color: white;
    display: block;
    border-top: 1px solid white;
    font-weight: bold;
}

.footer-mobile {
    font-size: smaller;
}

.footer-primary {
    height: 20px;
}

.footer-post {
    height: 50px;
    text-align: center;
    line-height: 40px;
    border-top: 1px solid white;
}

.footer-linkout {
    color: white;
    text-decoration: none;
}
.horz-ads {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    background-color: var(--logosecondcolor);
    color: white;
    width: 100vw;
    max-width: 728px;
    height: 90px;
    max-height: 90px;
    margin: 10px 0px;
}

@media screen and (min-width: 1880px) {
    .horz-ads {
        width: 100vw;
        max-width: 970px;
        height: 250px;
        max-height: 250px;
    }
}
.ad-box {
    width: 640px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ad-box-half {
    width: 320px;
}

.ad-box-row {
    display: flex;
    justify-content: space-between;
}

.ad-box-row-ad {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    background-color: var(--logosecondcolor);
    width: 300px;
    height: 250px;
    margin: 10px 10px;
    color: white;
}

.ad-box-row-adsense {
    width: 300px;
    height: 250px;
}
.Details-container {
    border: 2px solid black;
    max-width: 760px;
    min-width: 640px;
    margin: 10px;
    /* Allows for two rows of Ads */
    min-height: 540px;
}

.Details-container-mobile {
    border: none;
    min-height: 0px;
    min-width: 0px;
    display: flex;
    justify-content: center;
    width: 90%;
}

.Details {
    padding: 20px;
    font-size: large;
}

.Details-mobile {
    padding: 10px 0px;
}
.player-container {
    padding: 0px 20px;
    width: calc(100% - 360px);
    min-width: 485px;
}

.video-title {
    padding: 15px 10px;
    font-size: x-large;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.video-title-mobile {
    padding: 10px 0px;
    font-weight: bold;
    font-size: large;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    margin: auto;
    max-width: 760px;
}

@media (orientation: landscape) {
    .player-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 0px;
        width: 100%;
    }

    .player-mobile {
        /*width: 60%;*/
        width: 100%;
        height: 100%;
    }
}

@media (orientation: landscape) and (max-width: 767px){
    .player-mobile {
        /*width: 80%;*/
        width: 100%;
        height: 100%;
    }
}

@media (orientation: portrait) {
    .player-container-mobile {
        padding: 0px 0px;
        width: 100%;
    }
}
.results-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4% 4% 40px 4%;
    min-height: 960px;
}

.results-header {
    display: flex;
    width: 80%;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 0px 10px 10px 10px;
    margin: 0px 0px 10px 0px;
    font-size: 22px;
    border-bottom: 1px solid gray;
}

.results {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 20px;
    max-width: 1480px;
    width: 100%;
}

.results-result-new {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display:flex;
    flex-direction: column;
    height: 220px;
    margin: 8px 0px;
    box-sizing: border-box;
    cursor: pointer;
    color: black;
    align-items: center;
}

.results-thumbnail-new {
    cursor: pointer;
    width: 320px;
    height: 180px;
    background-color: #e2e1e1;
}

.results-title-new {
    cursor: pointer;
    text-align: left;
    color: black;
    font-family: 'Quicksand', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 320px;
    padding: 8px 0px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    pointer-events: none;
    box-sizing: border-box;
    text-overflow: ellipsis;
}


.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
}

.spotlight {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.primary-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px 20px;
    max-width: 1480px;
}

.secondary-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px 20px;
    max-width: 1480px;
}

.secondary-container-mobile {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.primary-container-mobile {
    align-items: flex-start;
    padding: 10px 0px 0px 0px;
    position: -webkit-sticky;
    position: sticky;
    top: -10px;
    background-color: white;
    border-bottom: 2px solid var(--logodarkcolor);
}


@media (orientation: landscape) and (max-width: 767px){
    .primary-container-mobile {
        position: unset;
        position: unset;
    }
}
