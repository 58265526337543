.player-container {
    padding: 0px 20px;
    width: calc(100% - 360px);
    min-width: 485px;
}

.video-title {
    padding: 15px 10px;
    font-size: x-large;
    font-weight: bold;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}

.video-title-mobile {
    padding: 10px 0px;
    font-weight: bold;
    font-size: large;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
    width: 90%;
    margin: auto;
    max-width: 760px;
}

@media (orientation: landscape) {
    .player-container-mobile {
        display: flex;
        flex-direction: column;
        align-items: center;
        padding: 0px 0px;
        width: 100%;
    }

    .player-mobile {
        /*width: 60%;*/
        width: 100%;
        height: 100%;
    }
}

@media (orientation: landscape) and (max-width: 767px){
    .player-mobile {
        /*width: 80%;*/
        width: 100%;
        height: 100%;
    }
}

@media (orientation: portrait) {
    .player-container-mobile {
        padding: 0px 0px;
        width: 100%;
    }
}