.app {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
    min-height: 100vh;
    width: 100%;
}

.spotlight {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
}

.primary-container {
    display: flex;
    justify-content: center;
    width: 100%;
    padding: 5px 20px;
    max-width: 1480px;
}

.secondary-container {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
    padding: 10px 20px;
    max-width: 1480px;
}

.secondary-container-mobile {
    display: flex;
    justify-content: space-evenly;
    width: 100%;
}

.primary-container-mobile {
    align-items: flex-start;
    padding: 10px 0px 0px 0px;
    position: -webkit-sticky;
    position: sticky;
    top: -10px;
    background-color: white;
    border-bottom: 2px solid var(--logodarkcolor);
}


@media (orientation: landscape) and (max-width: 767px){
    .primary-container-mobile {
        position: unset;
        position: unset;
    }
}