.horz-ads {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    background-color: var(--logosecondcolor);
    color: white;
    width: 100vw;
    max-width: 728px;
    height: 90px;
    max-height: 90px;
    margin: 10px 0px;
}

@media screen and (min-width: 1880px) {
    .horz-ads {
        width: 100vw;
        max-width: 970px;
        height: 250px;
        max-height: 250px;
    }
}