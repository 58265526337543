.Details-container {
    border: 2px solid black;
    max-width: 760px;
    min-width: 640px;
    margin: 10px;
    /* Allows for two rows of Ads */
    min-height: 540px;
}

.Details-container-mobile {
    border: none;
    min-height: 0px;
    min-width: 0px;
    display: flex;
    justify-content: center;
    width: 90%;
}

.Details {
    padding: 20px;
    font-size: large;
}

.Details-mobile {
    padding: 10px 0px;
}