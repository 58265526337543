.header {
    width: 100%;
    top: 0px;
    left: 0px;
    right: 0px;
    display: flex;
    justify-content: space-between;
    height: 64px;
    text-align: center;
    background-color: var(--bgcolor);
    box-shadow: 1px 2px 4px rgba(0, 0, 0, .5);
}

.header-mobile {
    justify-content: center;
    height: 36px;
}

.header-logo {
    width: 240px;
    height: 100%;
    padding: 0px 5%;
}

.header-logo-image {
    width: 180px;
    height: 100%;
    background-image: url('/images/logo.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    margin: auto;
}