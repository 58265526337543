.footer {
    width: 100%;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    background-color:black;
    color: white;
    display: block;
    border-top: 1px solid white;
    font-weight: bold;
}

.footer-mobile {
    font-size: smaller;
}

.footer-primary {
    height: 20px;
}

.footer-post {
    height: 50px;
    text-align: center;
    line-height: 40px;
    border-top: 1px solid white;
}

.footer-linkout {
    color: white;
    text-decoration: none;
}