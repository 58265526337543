.results-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 4% 4% 40px 4%;
    min-height: 960px;
}

.results-header {
    display: flex;
    width: 80%;
    box-sizing: border-box;
    font-family: 'Quicksand', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    padding: 0px 10px 10px 10px;
    margin: 0px 0px 10px 0px;
    font-size: 22px;
    border-bottom: 1px solid gray;
}

.results {
    display: flex;
    justify-content: space-evenly;
    flex-direction: row;
    flex-wrap: wrap;
    padding: 10px 20px;
    max-width: 1480px;
    width: 100%;
}

.results-result-new {
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    display:flex;
    flex-direction: column;
    height: 220px;
    margin: 8px 0px;
    box-sizing: border-box;
    cursor: pointer;
    color: black;
    align-items: center;
}

.results-thumbnail-new {
    cursor: pointer;
    width: 320px;
    height: 180px;
    background-color: #e2e1e1;
}

.results-title-new {
    cursor: pointer;
    text-align: left;
    color: black;
    font-family: 'Quicksand', sans-serif;
    -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
    width: 320px;
    padding: 8px 0px;
    font-size: 16px;
    font-weight: bold;
    line-height: 20px;
    overflow: hidden;
    white-space: nowrap;
    pointer-events: none;
    box-sizing: border-box;
    text-overflow: ellipsis;
}