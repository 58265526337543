@import url('https://fonts.googleapis.com/css?family=Montserrat|Open+Sans|Quicksand&display=swap');

:root {
    --bgcolor: #fff;
    --logodarkcolor : #3C4858;
    --logosecondcolor: #545F6D;
    --primaryBlue: #1093c9;
    --activeBlue: #107aa5;
    --inactiveBlue: #1093c9;
}

body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Quicksand', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}