.ad-box {
    width: 640px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
}

.ad-box-half {
    width: 320px;
}

.ad-box-row {
    display: flex;
    justify-content: space-between;
}

.ad-box-row-ad {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: xx-large;
    background-color: var(--logosecondcolor);
    width: 300px;
    height: 250px;
    margin: 10px 10px;
    color: white;
}

.ad-box-row-adsense {
    width: 300px;
    height: 250px;
}